<template>
  <section id="invoices">
    <b-card-actions
      title="Filters"
      action-collapse
    >
      <b-form-group
        label="Selected Hubs"
      >
        <v-select
          multiple
          v-model="selectedHubs"
          label="name"
          :options="hubs"
          @input="changeSelectedHubs"
        />
      </b-form-group>
      <b-form-group
        label="Search"
      >
        <b-form-input
          class="d-inline-block mr-1"
          placeholder="Search..."
          v-model="searchInputValue"
          @keyup.enter="searchInvoice"
        />
      </b-form-group>
    </b-card-actions>
    <b-card>
      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :current-page="invoicesTable.currentPage"
        :items="invoicesTable.items"
        :fields="invoicesTable.fields"
        :sort-by.sync="invoicesTable.sortBy"
        :sort-desc.sync="invoicesTable.sortDesc"
        :sort-direction="invoicesTable.sortDirection"
        :filter="invoicesTable.filter"
        :filter-included-fields="invoicesTable.filterOn"
        @row-clicked="rowClicked"
      >
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- pagination -->
        <div>
          <b-pagination
            v-model="invoicesTable.currentPage"
            :total-rows="invoicesTable.totalRows"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @change="handlePageChange"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
    <b-modal
      size="xl"
      ref="show-invoice-details-modal"
      cancel-title="Close"
      cancel-variant="outline-secondary"
      :no-close-on-backdrop="true"
    >
      <template #modal-title>
        Invoice {{ invoiceNo }} for Order {{ orderNo }}
      </template>
      <embed :src="encodedPDF" width="100%" height="900px"/>
      <hr>
      <b-button
        block
        variant="primary"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        v-if="encodedPDF"
        :disabled="disabledButton"
        @click="regeneratInvoicePDF"
      >
        <b-spinner small
          v-if="disabledButton"
        />
        Regenerate Invoice PDF
      </b-button>
    </b-modal>
  </section>
</template>

<script>
import {
  BTable,
  BPagination,
  BButton,
  BCardBody,
  BCard,
  BFormGroup,
  BFormInput,
  BModal,
  BSpinner,
} from "bootstrap-vue"
import vSelect from "vue-select"
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from "vue-ripple-directive"
import axios from "axios"

export default {
  components: {
    BTable,
    BPagination,
    BButton,
    BCardBody,
    BCard,
    BFormGroup,
    BFormInput,
    BModal,
    BSpinner,
    BCardActions,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      hubs: [],
      selectedHubs: [],
      searchInputValue: "",
      queryParams: {},
      invoicesTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: "number",
        sortDesc: true,
        sortDirection: "desc",
        filter: null,
        filterOn: [],
        infoModal: {
          id: "info-modal",
          title: '',
          content: '',
        },
        fields: [
          { key: "number", label: "Invoice number", sortable: true },
          { key: "order_number", label: "Order number", sortable: true },
          { key: "hub_name", label: "hub name", sortable: true },
          { key: "customer_name", label: "customer name", sortable: true },
          { key: "total_amount_net", label: "Total amount net", sortable: true },
          { key: "total_amount", label: "Total amount", sortable: true },
          { key: "payment_method", label: "Payment method", sortable: true },
          { key: "shipping_method", label: "Shipping method", sortable: true },
          {
            key: "invoice_date",
            label: "Invoice date",
            sortable: true,
            formatter: value => {
              let new_date = new Date(value)
              if (value) {
                return new_date.toLocaleDateString("de-DE")
              }
              else {
                return ""
              }
            }
          },
          { key: "pdf_invoice", label: "PDF invoice", sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      invoiceNo: "",
      orderNo: "",
      encodedPDF: "",
      disabledButton: false,
    }
  },
  methods: {
    getMyHubs() {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/hubs/all/my/", {
          headers: {
            "Authorization": `JWT ${this.$store.state.jwt}`,
            "Content-Type": "application/json"
          },
        })
        .then(response => {
          const myHubs = response.data.results.map( item => { return item.name})
          this.hubs = myHubs
          this.selectedHubs = myHubs
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$router.push({ name: "login" })
          }
        })
    },
    getInvoices() {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/invoices/", {
          headers: {
            "Authorization": `JWT ${this.$store.state.jwt}`,
            "Content-Type": "application/json"
          },
          params: this.queryParams,
        })
        .then(response => {
          this.invoicesTable.items = response.data.results
          this.invoicesTable.totalRows = response.data.count * 2
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$router.push({ name: "login" })
          }
        })
    },
    getInvoiceDetails(invoiceID) {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/invoices/" + invoiceID + "/", {
          headers: {
            "Authorization": `JWT ${this.$store.state.jwt}`,
            "Content-Type": "application/json"
          },
        })
        .then((response) => {
          this.invoiceNo = response.data.number
          this.orderNo = response.data.order_number
          this.encodedPDF = response.data.encoded_pdf
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$router.push({ name: "login" })
          }
        })
    },
    regeneratInvoicePDF() {
      this.disabledButton = true
      axios
        .get(process.env.VUE_APP_ROOT_API + "/regenerate-invoice-pdf/", {
          headers: {
            "Authorization": `JWT ${this.$store.state.jwt}`,
            "Content-Type": "application/json"
          },
          params: {order_no: this.orderNo},
        })
        .then((response) => {
          console.log(response)
          this.disabledButton = false
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$router.push({ name: "login" })
          }
        })
    },
    rowClicked(row) {
      this.$refs["show-invoice-details-modal"].show()
      this.getInvoiceDetails(row["id"])
    },
    handlePageChange(value) {
      this.queryParams["limit"] = 10
      this.queryParams["offset"] = (value-1) * 10
      this.getInvoices()
    },
    changeSelectedHubs() {
      this.queryParams["selected_hubs"] = this.selectedHubs
      this.getInvoices()
    },
    searchInvoice() {
      this.queryParams["search"] = this.searchInputValue
      this.getInvoices()
    },
  },
  created() {
    this.getMyHubs()
    this.getInvoices()
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
